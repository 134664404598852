import React from 'react';

function Services() {
  const titleStyle = {
    display: 'flex',
    fontSize: 30,
    fontWeight: 'bold',
    justifyContent: 'center',
  };
  return <div>Services</div>;
}

export default Services;
