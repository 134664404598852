import React, { useState } from 'react';
import { FaPeriscope, FaTelegramPlane, FaWhatsapp, FaTwitter, FaPhone, FaGlobe, FaArrowAltCircleRight } from 'react-icons/fa';
import * as Yup from 'yup';
import { Formik, Form, Field, ErrorMessage } from 'formik';
import badregLogo from '../Images/BadregT.png';
import zemen from '../Images/paymentlogo/zemen.png';
import cbe from '../Images/paymentlogo/cbe.jpg';
import dashen from '../Images/paymentlogo/dashen.png';
import united from '../Images/paymentlogo/united.png';
import telebir from '../Images/paymentlogo/telebir.jpg';
import abysinia from '../Images/paymentlogo/abysinia.png';

function Appointment() {

  const registerUser = (values) => {
    console.log(values);
  };

  const RegisterSchema = Yup.object().shape({
    fullname: Yup.string().min(3, 'Full Name must be 3 characters at minimum').required('Full Name  is required'),
    email: Yup.string().email('Invalid email address format').required('Email is required'),
    phonenumber: Yup.number().typeError("That doesn't look like a phone number").positive("A phone number can't start with a minus").integer("A phone number can't include a decimal point").min(8).required('A phone number is required'),
    speciality: Yup.string().required('Select your speciality'),
    course: Yup.string().required('Enter your course'),
  });

  const titleStyle = {
    display: 'flex',
    fontSize: 30,
    fontWeight: 'bold',
    justifyContent: 'center',
    color: '#f59028',
  };

  const titleSubStyle = {
    display: 'flex',
    fontSize: 30,
    fontWeight: 'bold',
    justifyContent: 'center',
    color: '#f59028',
    // @media
  };

  const addressHeader = {
    fontSize: 25,
    paddingTop: 10,
  };

  const footerTitle = {
    fontWeight: 'bold',
    paddingBottom: 20,
    fontSize: 25,
    color: '#f59028',
  };

  return (
    <div className="container">
      <div className="row">
        <div className="col-sm-6" style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', padding: 10 }}>
          <img style={{ height: 250, width: 250 }} src={badregLogo} alt="logo" />
        </div>
        <div className="col-sm-5" style={{ padding: 10 }}>
          <Formik initialValues={{ fullname: '', email: '', phonenumber: '', course: '', speciality: '' }} validationSchema={RegisterSchema} onSubmit={(values) => registerUser(values)}>
            {({ touched, errors, isSubmitting, values }) =>
              !isSubmitting ? (
                <div>
                  <Form>
                    <div className="form-group">
                      <label htmlFor="fullname" className="mt-3">
                        Full Name
                      </label>
                      <Field
                        type="text"
                        name="fullname"
                        placeholder="Enter First Name"
                        className={`mt-2 form-control
                          ${touched.fullname && errors.fullname ? 'is-invalid' : ''}`}
                      />
                      <ErrorMessage component="div" name="fullname" className="invalid-feedback" />
                    </div>

                    <div className="form-group">
                      <label htmlFor="email">Email</label>
                      <Field
                        type="email"
                        name="email"
                        placeholder="Enter email"
                        className={`mt-2 form-control
                          ${touched.email && errors.email ? 'is-invalid' : ''}`}
                      />
                      <ErrorMessage component="div" name="email" className="invalid-feedback" />
                    </div>

                    <div className="form-group">
                      <label htmlFor="phonenumber"> Telephone </label>
                      <Field
                        type="text"
                        name="phonenumber"
                        placeholder="Enter Phone Number"
                        className={`mt-2 form-control
                          ${touched.phonenumber && errors.phonenumber ? 'is-invalid' : ''}`}
                      />
                      <ErrorMessage component="div" name="phonenumber" className="invalid-feedback" />
                    </div>

                    <div className="form-group">
                      <label htmlFor="course">Course</label>
                   
                      <Field
                        name="course"
                        component="select"
                        className={`mt-2 form-control
                          ${touched.speciality && errors.speciality ? 'is-invalid' : ''}`}
                      >
                        <option>Select Course</option>
                        <option value="1"> Safe handling of chemotherapeutic drugs </option>
                        <option value="2"> Clinical Pharmacy from Theory to Practice </option>
                        <option value="3"> Clinical pharmacokinetics and principles of chemotherapy </option>
                        <option value="4"> Antimicrobial resistance prevention and containment </option>
                        <option value="5"> Integrated pharmaceutical logistics system (IPLS) </option>
                        <option value="6"> Compounding and hospital manufacturing </option>
                        <option value="7"> Traditional medicine </option>

                      </Field>
                    </div>

                    <div className="form-group">
                      <label htmlFor="speciality">Speciality</label>
                      <Field
                        name="speciality"
                        component="select"
                        className={`mt-2 form-control
                          ${touched.speciality && errors.speciality ? 'is-invalid' : ''}`}
                      >
                        <option>Select Your Profession</option>
                        <option value="1"> Doctor </option>
                        <option value="2"> Pharmacists </option>
                        <option value="3"> Nurse </option>
                        <option value="4"> Health Officer </option>
                        <option value="5"> Lab Technician </option>
                        <option value="6"> Other </option>
                      </Field>
                    </div>

                    {values.speciality === 6 && (
                      <div className="form-group">
                        <label htmlFor="phonenumber"> Other </label>
                        <Field
                          type="text"
                          name="phonenumber"
                          placeholder="Enter Phone Number"
                          className={`mt-2 form-control
                          ${touched.phonenumber && errors.phonenumber ? 'is-invalid' : ''}`}
                        />
                        <ErrorMessage component="div" name="phonenumber" className="invalid-feedback" />
                      </div>
                    )}
                    <div className="row mb-3">
                      <div className="col-lg-12 text-center">
                        <button type="submit" style={{ borderColor: '#F59028', backgroundColor: '#F59028', color: '#fff', fontWeight: 'bold' }} className="btn btn-primary btn-block mt-4">
                          REGISTER
                        </button>
                      </div>
                    </div>
                  </Form>
                </div>
              ) : (
                <div>
                  <p>
                    Dear: 
                    <b>
                      { values.fullname} 
                    </b>
                  </p>
                  <div className="alert alert-success mt-3">Thanks for Registering we will notify you with the details of course outline soon. </div>

                  <p> Regards </p>
                </div>
              )
            }
          </Formik>
        </div>
      </div>

      <div className="row">
        <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', flexDirection: 'column', rowGap: 10, paddingTop: 25 }}>
          {/* <p style={titleSubStyle}> Send us a message </p> */}
          <p style={footerTitle}>EASY PAYMENT OPTIONS</p>
          <p> You can make Payments through Commercial Bank Of Ethiopia, Dashen Bank, United Bank, Zemen Bank and Bank of Abyssinia. </p>
        </div>

        <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', flexDirection: 'row', rowGap: 10, paddingBottom: 25, paddingTop: 25 }}>
          <img style={{ height: 90, width: 90 }} src={dashen} alt="logo" />
          <img style={{ height: 90, width: 90 }} src={cbe} alt="logo" />
          <img style={{ height: 90, width: 90 }} src={zemen} alt="logo" />
          <img style={{ height: 90, width: 90 }} src={telebir} alt="logo" />
          <img style={{ height: 90, width: 90 }} src={abysinia} alt="logo" />
        </div>
      </div>
    </div>
  );
}

export default Appointment;
