import React, { useEffect, useState } from 'react';
import Carousel from 'react-bootstrap/Carousel';
import Card from 'react-bootstrap/Card';
import Button from 'react-bootstrap/Button';

import blended from '../Images/LearningStyles/blended.jpg';
import facetoface from '../Images/LearningStyles/facetoface.png';
import online from '../Images/LearningStyles/online.jpg';

import CPD from '../Images/CPD.png';

import { Link } from 'react-router-dom';

import { ClipLoader, GridLoader } from 'react-spinners';
// import Rectangles from './Rectangles';

import Media from 'react-media';

function Home() {
  const headingStyles = {
    fontSize: 40,
    color: '#f59028',
    paddingTop: 30,
  };

  const RectangleOneSm = {
    width: '100%',
    backgroundColor: '#f59028',
    height: '50vh',
    borderWidth: 5,
    borderColor: '#fff',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    color: 'white',
  };
  const RectangleTwoSm = {
    position: 'relative',
    width: '100%',
    backgroundColor: '#fff',
    height: '50vh',
    borderWidth: 2,
    borderColor: '#fff',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
  };

  const RectangleOne = {
    width: '50%',
    backgroundColor: '#f59028',
    height: '50vh',
    borderWidth: 5,
    borderColor: '#fff',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    color: 'white',
  };
  const RectangleTwo = {
    width: '50%',
    backgroundColor: '#fff',
    height: '50vh',
    borderWidth: 2,
    borderColor: '#fff',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
  };

  const booknowBtn = {
    backgroundColor: '#f59028',
    padding: 20,
    borderWidth: 5,
    borderColor: '#fff',
    borderRadius: 10,
  };


  return (
    <>
      <Media queries={{ small: { maxWidth: 599 } }}>
        {matches =>
          matches.small ? (
            <div>

              <div style={{ display: 'flex', flexDirection: 'row' }}>
                <div style={RectangleOneSm}>
                  <div style={{ fontSize: '10vw', fontWeight: 'bold' }}> BADREG </div>
                  <div style={{ fontSize: '10vw', fontWeight: 'bold' }}> CPD </div>
                </div>
              </div>

              <div style={RectangleTwoSm}>
                <img style={{ height: '100%', width: '100%' }} src={CPD} alt="logo" />
              </div>
              
              <div style={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center', position: 'relative', paddingTop: '5vh' }}>
                <button style={booknowBtn}>
                  <Link style={{ textDecoration: 'none', borderWidth: 5, borderColor: '#fff', color: '#fff', fontWeight: 'bold' }} to="/Appointment">
                    BOOK NOW!
                  </Link>
                </button>
              </div>

            </div>
          ) : (
            <div>
              <div style={{ width: '100%', position: 'absolute', paddingTop: 10 }}>
                <div style={{ display: 'flex', flexDirection: 'row' }}>
                  <div style={RectangleOne}>
                    <div style={{ fontSize: '6vw', fontWeight: 'bold' }}> BADREG </div>
                    <div style={{ fontSize: '6vw', fontWeight: 'bold' }}> CPD </div>
                  </div>
                  <div style={RectangleTwo}>
                    <img style={{ height: '100%', width: '100%' }} src={CPD} alt="logo" />
                  </div>
                </div>
              </div>

              <div style={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center', position: 'relative', paddingTop: '25vh' }}>
                <button style={booknowBtn}>
                  <Link style={{ textDecoration: 'none', borderWidth: 5, borderColor: '#fff', color: '#fff', fontWeight: 'bold' }} to="/Appointment">
                    BOOK NOW!
                  </Link>
                </button>
              </div>
            </div>
          )
        }
      </Media>

      {/* OBJECTIVES START  */}
      <div style={{ display: 'flex', justifyContent: 'center', paddingTop: '15vh', position: 'relative' }} className="container">
        <p style={headingStyles}> WHO WE ARE </p>
      </div>

      <div className="container">
        <p> Badreg Pvt. Ltd. Co. is one of the most reputable companies in Ethiopia which is primarily engaged in the business of import and
          distribution of quality, affordable and diversified range of pharmaceutical products, cosmetics, auto paint, electrical goods and
          building materials.</p>
        <p> Badreg Pvt. Ltd. Co. was founded in 1997 and right from our humble beginnings we always strived to provide the highest quality products
          at the best value for money. Our product supply range expanded over the years combined with a commitment to our customers to provide
          the highest standards of services that became in-built in our work culture and practices.</p>
        <p> Continuing Professional Development (CPD) is a career-long process that involves the tracking and documenting of skills,
          knowledge and experience gained, both formally and informally, beyond any initial education and training.
          It helps Health care providers and Health professionals to keep their practice and proficiency up to date to maintain standards.
          All Health care providers and Health professionals must undertake Continuing Professional Development (CPD) on an annual basis in order
          to be eligible to continue their registration.</p>
        <p>
          As health care providers, Health professionals are accountable to the patients they care for by ensuring their knowledge and
          skills are up-to-date and they remain competent to practice. Professional development for Health care providers and Health professionals
          is important because it helps them stay current with new developments in the field of Health and improve their skills.
          We <b> Badreg PLC </b> are happly involved in training the professionals so that Health care providers and Health professionals
          could be able to keep their practice and proficiency up to date to maintain standards
        </p>
      </div>

      {/* OBJECTIVES END  */}

      {/* VISION MISSION and VALUES START  */}
      <div style={{ display: 'flex', justifyContent: 'center', paddingBottom: 10 }} className="container">
        <p style={headingStyles}> MISSION, VISION & CORE VALUES </p>
      </div>
      <div className="container" style={{ paddingTop: 20 }}>
        <div className="row">
          <div className="col-sm-4">
            <p>
              <b> TARGET AUDIENCE </b>
            </p>
            <p> Healthcare professionals willing to take training in different areas. </p>
          </div>
          <div className="col-sm-8">
            <div style={{ paddingBottom: 10 }} className="container">
              <p style={{ color: '#f59028', fontWeight: 'bold' }}> Our Mission Statement </p>
              <p> To provide high-quality continuing professional development opportunities that enhance the knowledge, skills, and abilities of healthcare professionals. </p>

              <p style={{ color: '#f59028', fontWeight: 'bold' }}> Our Vision Statement </p>
              <p> To be recognized as a leader in providing continuing professional development opportunities that enhance the knowledge, skills, and abilities of healthcare professionals. </p>

              <p style={{ color: '#f59028', fontWeight: 'bold' }}> Our Core Values </p>
              <ul>
                <li> Person Contentedness </li>
                <li> Professionalism </li>
                <li> Ownership </li>
                <li> Innovation </li>
                <li> Excellence </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
      {/* VISION MISSION and VALUES END  */}

      {/* CPD APPROACHES START  */}
      <div style={{ display: 'flex', justifyContent: 'center', paddingBottom: 10 }} className="container">
        <p style={headingStyles}> CPD APPROACHES </p>
      </div>
      <div style={{ paddingBottom: 10 }} className="container">
        <div className="row">
          <div className="col-sm-4">
            <Card>
              <Card.Img variant="top" src={facetoface} />
              <Card.Body>
                <Card.Title>Face to Face</Card.Title>
                <Card.Text> It’s one of the training modality used at BADREG CPD center. Face-to-face learning is an instructional method where course content and learning material are taught in person to a group of students. This allows for a live interaction between a learner and an instructor. </Card.Text>
              </Card.Body>
            </Card>
          </div>
          <div className="col-sm-4">
            <Card>
              <Card.Img variant="top" src={online} />
              <Card.Body>
                <Card.Title> Online Learning </Card.Title>
                <Card.Text> It is one of the training modality/ a form of instruction that takes place completely on the internet. </Card.Text>
              </Card.Body>
            </Card>
          </div>
          <div className="col-sm-4">
            <Card>
              <Card.Img variant="top" src={blended} />
              <Card.Body>
                <Card.Title> Blended Leaning </Card.Title>
                <Card.Text> Blended learning is a learning approach that combines instructor-led brick-and-mortar classroom training and online learning activities. </Card.Text>
              </Card.Body>
            </Card>
          </div>
        </div>
      </div>
      {/* CPD APPROACHES END  */}

      {/* COURSE OVERVIEW START  */}
      <div style={{ display: 'flex', justifyContent: 'center', paddingBottom: 10 }} className="container">
        <p style={headingStyles}> COURSES </p>
      </div>
      <div className="container">

        <ul>
          <li> Safe handling of chemotherapeutic drugs. </li>
          <li> Clinical Pharmacy from Theory to Practice. </li>
          <li> Clinical pharmacokinetics and principles of chemotherapy. </li>
          <li> Antimicrobial resistance prevention and containment. </li>
          <li> Integrated pharmaceutical logistics system (IPLS). </li>
          <li> Compounding and hospital manufacturing. </li>
          <li> Traditional medicine. </li>
        </ul>
      </div>
      <div className="container" style={{ paddingTop: 20 }}></div>


      {/* COURSE OVERVIEW END  */}


    </>
  );
}

export default Home;
